import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProduct } from "../../actions/productAction";
import Loader from "../layouts/loader/Loader";
import ProductCard from "../Home/ProductCard";
import Pagination from "react-js-pagination";
import "./CategoryPage.css";

function CategoryPage() {
  const dispatch = useDispatch();
  const { category } = useParams(); // Get the category from the URL

  // Manage pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const { products, loading, productsCount, resultPerPage } = useSelector(
    (state) => state.products
  );

  // Fetch products whenever category or page changes
  useEffect(() => {
    dispatch(getProduct("", currentPage, [0, 100000], category,0));
  }, [dispatch, category, currentPage]);

  // Handle page change
  const setCurrentPageNoHandler = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="categoryPage">
      {/* <h1>{category} Products</h1> */}
      {loading ? (
        <Loader />
      ) : (
        <div className="productGrid">
          {products && products.map((product) => (
            <ProductCard key={product._id} product={product} />
          ))}
        </div>
      )}

      {/* Show pagination only if there are products */}
      {productsCount > resultPerPage && (
        <div className="paginationBox">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={resultPerPage}
            totalItemsCount={productsCount}
            onChange={setCurrentPageNoHandler}
            nextPageText="Next"
            prevPageText="Prev"
            firstPageText="First"
            lastPageText="Last"
            itemClass="page-item"
            linkClass="page-link"
            activeClass="pageItemActive"
            activeLinkClass="pageLinkActive"
          />
        </div>
      )}
    </div>
  );
}

export default CategoryPage;
